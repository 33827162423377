import React, { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import './pannellum/css/video-js.css'
import './pannellum/css/pannellum.css'
import './pannellum/css/style-textInfo.css'
import './pannellum/js/libpannellum.js'
import './pannellum/js/pannellum.js'
import './pannellum/js/videojs-pannellum-plugin'

export default function PannellumVideo(props) {
  const [id, setId] = useState(Math.random().toString(36).substr(2, 9))
  let videoNode = useRef()

  const renderVideo = (state) => {
    const { children } = props
    let hotspotArray = []

    if (children) {
      // make the array of sub components, even if its one, it become array of one 
      let hotspots = [...children]
      if (Array.isArray(hotspots)) {
        hotspots.map(hotspot => {
          switch (hotspot.props.type) {
            case "info":
              return hotspotArray.push({
                "id": Math.random().toString(36).substr(2, 9),
                "type": hotspot.props.type,
                "pitch": hotspot.props.pitch ? hotspot.props.pitch : 10,
                "yaw": hotspot.props.yaw ? hotspot.props.yaw : 10,
                "text": hotspot.props.text ? hotspot.props.text : "",
                "URL": hotspot.props.URL ? hotspot.props.URL : ""
              })
            case "custom":
              return hotspotArray.push({
                "id": Math.random().toString(36).substr(2, 9),
                "pitch": hotspot.props.pitch ? hotspot.props.pitch : 10,
                "yaw": hotspot.props.yaw ? hotspot.props.yaw : 10,
                "cssClass": hotspot.props.cssClass ? hotspot.props.cssClass : 'tooltipcss',
                "createTooltipFunc": hotspot.props.tooltip ? hotspot.props.tooltip : hotspotTooltip,
                "createTooltipArgs": hotspot.props.tooltipArg ? hotspot.props.tooltipArg : {},
                "clickHandlerFunc": hotspot.props.handleClick ? hotspot.props.handleClick : handleClickHotspot,
                "clickHandlerArgs": hotspot.props.handleClickArg ? hotspot.props.handleClickArg : { name: "test" },
              })
            default:
              return []
          }
        })
      }

    }

    if (state === "update") {
      video = videojs(videoNode)
      let cuurentHS = [...video.pnlmViewer.getConfig().hotSpots]
      video.pnlmViewer.setYaw(props.yaw)
      video.pnlmViewer.setPitch(props.pitch)
      video.pnlmViewer.setHfov(props.hfov)
      video.pnlmViewer.setHfovBounds([props.minHfov, props.maxHfov])

      //remove all hotspots
      cuurentHS.map(hs => video.pnlmViewer.removeHotSpot(hs.id))
      // Adding new hotspots
      hotspotArray.map(hs => video.pnlmViewer.addHotSpot(hs))
      // setting new video
      video.src({
        type: 'video/mp4',
        src: props.video
      })
      video.ready(() => {
        onReady()
      })

      return
    } else {
      video = videojs(videoNode, {
        loop: props.loop,
        autoplay: props.autoplay,
        controls: props.controls,
        muted: props.muted,
        plugins: {
          pannellum: {
            yaw: props.yaw,
            pitch: props.pitch,
            hfov: props.hfov,
            minHfov: props.minHfov,
            maxHfov: props.maxHfov,
            minPitch: props.minPitch,
            maxPitch: props.maxPitch,
            minYaw: props.minYaw,
            maxYaw: props.maxYaw,
            hotSpotDebug: props.hotspotDebug,
            autoRotate: props.autoRotate,
            mouseZoom: props.mouseZoom,
            hotSpots: hotspotArray
          }
        }
      })
      video.src({ type: 'video/mp4', src: props.video })
      video.ready(() => {
        onReady()
      })
    }
  }

  useEffect(() => {
    // fix: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#effect-cleanup-timing
    const node = videoNode;
    renderVideo("mount")
    return () => {
      videojs(node).dispose()
    }
  }, [])

  useEffect(() => {
    renderVideo("update")
  }, [props])

  // fix: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  function onReady() {
    if (video) {
      video.play()
        .then(() => { })
        .catch(() => { })
    }
  }

  const handleClickHotspot = (e, args) => {
    console.log("hotspot clicked", args.name)
  }


  const hotspotTooltip = (hotSpotDiv, args) => {
    hotSpotDiv.setAttribute("id", "textInfo")
    const hDiv = document.createElement('div')
    hDiv.classList.add('hotspot')
    const outDiv = document.createElement('div')
    outDiv.classList.add('out')
    const inDiv = document.createElement('div')
    inDiv.classList.add('in')
    const imageDiv = document.createElement('div')
    imageDiv.classList.add('image')
    hotSpotDiv.appendChild(hDiv)
    hDiv.appendChild(inDiv)
    hDiv.appendChild(outDiv)
  }

  const getViewer = () => {
    return video.pnlmViewer
  }

  let { width, height, video } = props
  let divStyle = {
    width,
    height,
  }

  return (
    //fix: https://github.com/videojs/video.js/issues/4935
    <div data-vjs-player>
      <video-js
        id={props.id ? props.id : id}
        className="video-js vjs-default-skin vjs-big-play-centered"
        ref={node => videoNode = node}
        preload="none"
        crossOrigin="anonymous"
        style={divStyle}
      />
    </div>
  )
}
