import React, { useMemo, useState } from "react"
import { BackspaceIcon } from "@heroicons/react/solid"
import ArtCard from "@elements/ArtCard/ArtCard"
import Filter from "@elements/Filter/Filter"
import {
  getArtMediaUrl,
  getCountryImgUrl,
  onlyUnique,
  onlyUniqueByLabel,
  ucFirst,
} from "@util"
import artData from "@util/artData"
import InfiniteScroll from "react-infinite-scroll-component"
import PropagateLoader from "react-spinners/PropagateLoader"
import "./Explorer.scss"
import ArtDetails from '../ArtDetails/ArtDetails'

const types = artData.arts
  .sort((a, b) => a.type.localeCompare(b.type))
  .map(dt => dt.type)
  .filter(onlyUnique)
  .map(type => ({
    label: type,
    value: type,
  }))

const countries = artData.arts
  .sort((a, b) => a.countryCode.localeCompare(b.countryCode))
  .map(dt => dt.countryCode)
  .filter(onlyUnique)
  .map(countryCode => ({
    label: ucFirst(countryCode),
    value: countryCode,
    icon: (
      <img
        src={getCountryImgUrl(countryCode)}
        className="h-full w-full object-cover countryImg-sm mr-3"
      />
    ),
  }))

export default function ExplorerPage() {
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedArtists, setSelectedArtists] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [items, setItems] = useState([])
  const itemsPerPage = 12
  const [showDetails, setShowDetails] = useState(false)
  const [currentArt, setCurrentArt] = useState(null)

  const arts = useMemo(
    () => {
      const arts = artData.arts.sort((a, b) => a.name.localeCompare(b.name))
        .filter(dt => {
          let matched = true;
          if (selectedTypes.length) {
            matched &= !!selectedTypes.find(t => t.value === dt.type)
          }
          if (selectedArtists.length) {
            matched &= !!selectedArtists.find(a => a.value === dt.name)
          }
          if (selectedCountries.length) {
            matched &= !!selectedCountries.find(c => c.value === dt.countryCode)
          }
          return matched
        })
        .map((dt, idx) => ({
          title: dt.name,
          type: dt.type,
          id: idx,
          media: getArtMediaUrl(dt),
          ...dt,
        }))
      setItems(arts.slice(0, itemsPerPage))
      return arts
    },
    [selectedTypes, selectedArtists, selectedCountries]
  )

  const fetchMoreArt = () => {
    setTimeout(() => {
      setItems(items.concat(arts.slice(items.length, items.length + itemsPerPage)))
    }, 1000)
  }

  return (
    <div className="ExplorerPage py-24">
      <div className="mt-2">
        <div className="flex flex-col md:flex-row items-center justify-between mb-5">
          <div className="explorerHead mb-3">Artwork Explorer</div>
          <div className="flex sm:items-center flex-grow sm:flex-grow-0 self-stretch sm:self-center">
            <div className="flex gap-3 flex-col md:flex-row items-center md:items-start flex-grow sm:flex-grow-0 self-stretch sm:self-center">
              <div className="flex gap-3 md:flex-col">
                <Filter
                  placeholder="Type"
                  value={selectedTypes}
                  onChange={setSelectedTypes}
                  options={types}
                // mode="secondary"
                />
                <div className="text-white hidden md:block font-semibold mr-4 tracking-wider text-center">
                  Filter
                </div>
              </div>
              <div className="flex gap-3 md:flex-col">
                <Filter
                  placeholder="Artist"
                  value={selectedArtists}
                  onChange={setSelectedArtists}
                  options={artData.arts.sort((a, b) => a.name.localeCompare(b.name)).map(dt => ({
                    label: dt.name,
                    value: dt.name,
                  })).filter(onlyUniqueByLabel)}
                // mode="secondary"
                />
              </div>
              <div className="flex gap-3 md:flex-col">
                <Filter
                  placeholder="Country"
                  value={selectedCountries}
                  onChange={setSelectedCountries}
                  options={countries}
                // mode="secondary"
                />
                <div
                  onClick={() => {
                    setSelectedTypes([])
                    setSelectedCountries([])
                    setSelectedArtists([])
                  }}
                  className="text-primary font-semibold cursor-pointer mb-2 hidden md:block text-center items-center"
                >
                  <span className="flex items-center justify-center">
                    <BackspaceIcon
                      className="h-5 w-5 text-primary mr-2"
                      aria-hidden="true"
                    />
                    Clear All
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreArt}
            hasMore={items.length < arts.length}
            loader={<PropagateLoader color={"#99fdc7"} loading={true} />}
            style={{ 'overflow': 'false', 'textAlign': 'center' }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-10 -mx-container mb-6">
              {items.map((dt, index) => (
                <ArtCard
                  className="artCard"
                  onClickImg={() => {
                    setShowDetails(true)
                    setCurrentArt(dt)
                  }}
                  key={dt.id || `${index}`} {...dt}
                />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      {showDetails && (
        <ArtDetails
          open={showDetails}
          setOpen={setShowDetails}
          art={currentArt}
          filteredArts={arts}
        />)
      }
    </div>
  )
}
