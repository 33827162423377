import React from "react"
import { classNames, getCountryImgUrl, getPreviewUrl } from "@util"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/black-and-white.css"

export default function ArtCard(props) {
  return (
    <div
      onClick={() => props.onClickImg && props.onClickImg()}
      className={classNames(
        "flex flex-col rounded-2xl overflow-hidden relative cursor-pointer",
        props.title ? "border-primary border" : "",
        props.className
      )}
    >
      {
        <LazyLoadImage
          alt={"not found"}
          effect="black-and-white"
          src={getPreviewUrl(props)}
          className="artCardImg w-full"
        />
      }
      {props.title && (
        <div className="p-5 md:p-3 bg-secondary bg-opacity-90 absolute bottom-0 left-0 right-0">
          <div className="flex justify-start border-b border-primary pb-3">
            <img
              src={getCountryImgUrl(props.countryCode)}
              className="countryImg mr-3"
            />
            <div className="text-primary font-semibold text-lg">
              {props.title}
            </div>
          </div>
          <div className="flex justify-end">
            <div className="text-primary font-normal text-lg">
              {props.type}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
