import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from "@heroicons/react/solid"
import Button from "@elements/Button/Button"
import { getCountryImgUrl, capitalize, getArtMediaUrl, getArtAvailable, getOpenSeaUrl } from '@util'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactPannellum from "react-pannellum"
import PannellumVideo from "@components/elements/PannellumVideo/PannellumVideo"
import RegularVideo from "@components/elements/RegularVideo/RegularVideo"
import "./ArtDetails.scss"

export default function ArtDetails({
  art,
  open,
  setOpen,
  filteredArts,
}) {
  const [currentArt, setCurrentArt] = useState(art)
  const [horizontalType, setHorizontalType] = useState(null)

  const checkHorizontal = () => {
    setHorizontalType(window.innerWidth >= 1024 ? "large"
      : window.innerWidth >= 768 ? "middle"
        : window.innerWidth >= 425 ? "small"
          : window.innerWidth >= 320 ? "tiny" : "microscopic")
  }

  useEffect(() => {
    checkHorizontal()
    window.addEventListener("resize", checkHorizontal)
    return () => {
      window.removeEventListener("resize", checkHorizontal)
    }
  }, [])

  const prevArt = useMemo(() => {
    const index = filteredArts.findIndex(
      dt => dt.hashname === currentArt?.hashname
    )
    return filteredArts[index - 1]
  }, [currentArt])

  const nextArt = useMemo(() => {
    const index = filteredArts.findIndex(
      dt => dt.hashname === currentArt?.hashname
    )
    return filteredArts[index + 1]
  }, [currentArt])

  if (currentArt === null || currentArt === undefined)
    return null

  const configVRImage = {
    uiText: {
      loadButtonLabel: "Click to<br>Load<br>VR Image",
      bylineLabel: '',
    },
    autoLoad: true,
    showZoomCtrl: false,
  }

  const configVRVideo = {
    controls: false,
    loop: true,
    // autoplay: true,
    muted: false,
    disableKeyboardCtrl: true,
    width: "360px",
    height: "576px",
    pitch: 10,
    yaw: 180,
    hfov: 140,
    minHfov: 50,
    maxHfov: 150,
  }

  const configVideo = {
    controls: false,
    loop: true,
    // autoplay: true,
    muted: false,
  }

  const onClick = () => {
    if (currentArt.format !== "vr-image" && currentArt.format !== "vr-video" && currentArt.hashname)
      window.open(getArtMediaUrl(currentArt))
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <XIcon
            className="fixed top-5 right-5 text-primary z-50 cursor-pointer"
            width={30}
            onClick={() => setOpen(false)}
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-secondary bg-opacity-90 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              // className="inline-block h-screen overflow-hidden transition-all lg:align-middle lg:max-w-4xl lg:w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              className="inline-block h-screen transition-all lg:align-middle lg:w-full relative p-5 pt-10"
            >
              <div className="h-full flex lg:items-center justify-center">
                <div className="ArtDetails flex flex-col lg:flex-row">
                  <div className="flex flex-col">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                      <div className="artDetailsImg box-content lg:mr-32 border-primary border border-l-8 border-r-8 rounded-2xl p-2 mb-10 lg:mb-0">
                        <div
                          className="border-primary border flex justify-center w-full h-full"
                        >
                          {
                            currentArt.format === undefined || currentArt.format === "normal" ? (
                              <LazyLoadImage
                                alt={"Not found"}
                                effect="black-and-white"
                                src={getArtMediaUrl(currentArt)}
                                width="100%"
                                className="h-full text-white font-semibold text-center cursor-pointer"
                                onClick={onClick}
                              />
                            ) : (
                              currentArt.format === "video" ? (
                                <RegularVideo
                                  id={"regular-video"}
                                  video={getArtMediaUrl(currentArt)}
                                  {...configVideo}
                                />
                              ) : (
                                currentArt.format === "vr-video" ? (
                                  <PannellumVideo
                                    id={"pannellum-video"}
                                    video={getArtMediaUrl(currentArt)}
                                    {...configVRVideo}
                                  />
                                ) : (
                                  <ReactPannellum
                                    config={configVRImage}
                                    style={{
                                      width: "100%"
                                    },
                                    {
                                      height: horizontalType === "large" ? "576px"
                                        : horizontalType === "middle" ? "440px"
                                          : horizontalType === "small" ? "400px"
                                            : horizontalType === "tiny" ? "380px" : "288px"
                                    }}
                                    id="1"
                                    sceneId="firstScene"
                                    imageSource={getArtMediaUrl(currentArt)}
                                  />
                                )
                              )
                            )
                          }
                        </div>
                      </div>
                      <div className="artDetails flex flex-col justify-end lg:mb-10 text-lg md:text-2xl">
                        <div className="flex justify-between items-center mb-2 font-semibold">
                          <img
                            src={getCountryImgUrl(currentArt.countryCode)}
                            className="countryImg mr-3 items-center"
                          />
                          <div className="text-primary flex flex-row items-center">
                            <div>{capitalize(currentArt.countryCode)}</div>
                          </div>
                        </div>
                        <div className="detailsDescription flex items-end justify-between border-b border-primary font-semibold">
                          <div className="text-primary italic mb-2 mr-5">Type</div>
                          <div className="text-white mb-2">{currentArt.type}</div>
                        </div>
                        <div className="detailsDescription flex items-end justify-between border-b border-primary font-semibold">
                          <div className="text-primary italic mb-2 mr-5">Artist</div>
                          <div className="text-white mb-2 whitespace-nowrap	overflow-ellipsis">{currentArt.name}</div>
                        </div>
                        <div className="detailsDescription flex items-end justify-between border-b border-primary font-semibold">
                          <div className="text-primary italic mb-2 mr-5">Available</div>
                          <div className="text-white flex flex-row mb-2 whitespace-nowrap	overflow-ellipsis">{getArtAvailable(currentArt)}</div>
                        </div>
                        <div className="detailsDescription flex items-end justify-center">
                          <p className="text-primary italic mb-2">
                            <a
                              href={getOpenSeaUrl(currentArt)} target={"_blank"}
                              rel="noreferrer"
                              className="underline text-green-300 hover:text-green-100"
                            >
                              View On OpenSea
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-7 flex-col md:flex-row justify-between md:items-center mt-5 mb-5 md:mb-0 lg:mt-10 w-px-96">
                      {prevArt ? (
                        <Button
                          block={false}
                          variant="outline"
                          className="flex justify-center md:justify-between items-center w-full md:w-auto"
                          onClick={() => setCurrentArt(prevArt)}
                        >
                          <ChevronLeftIcon className="-ml-2 mr-2 lg:mr-0" width={22} />
                          <span style={{ lineHeight: "22px" }}>Previous Art</span>
                        </Button>
                      ) : (
                        <div />
                      )}
                      {nextArt ? (
                        <Button
                          block={false}
                          variant="outline"
                          className="flex justify-center md:justify-between items-center w-full md:w-auto"
                          onClick={() => setCurrentArt(nextArt)}
                        >
                          <span style={{ lineHeight: "22px" }}>Next Art</span>
                          <ChevronRightIcon className="-mr-2 ml-2 lg:ml-0" width={22} />
                        </Button>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
