import React, { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import './css/video-js.css'

export default function RegularVideo(props) {
  const [id, setId] = useState(Math.random().toString(36).substr(2, 9))
  let videoNode = useRef()

  const renderVideo = (state) => {

    if (state === "update") {
      video = videojs(videoNode)
      // setting new video
      video.src({
        type: 'video/mp4',
        src: props.video
      })
      video.ready(() => {
        onReady()
      })

      return
    } else {
      video = videojs(videoNode, {
        loop: props.loop,
        autoplay: props.autoplay,
        controls: props.controls,
        muted: props.muted,
      })
      video.src({ type: 'video/mp4', src: props.video })
      video.ready(() => {
        onReady()
      })
    }
  }

  useEffect(() => {
    // fix: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#effect-cleanup-timing
    const node = videoNode;
    renderVideo("mount")
    return () => {
      videojs(node).pause()
      videojs(node).dispose()
    }
  }, [])

  useEffect(() => {
    renderVideo("update")
  }, [props])

  let { width, height, video } = props
  let divStyle = {
    width,
    height,
  }

  // fix: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  function onReady() {
    if (video) {
      video.play()
        .then(() => { })
        .catch(() => { })
    }
  }

  return (
    //fix: https://github.com/videojs/video.js/issues/4935
    <div
      data-vjs-player
    >
      <video-js
        id={props.id ? props.id : id}
        className="video-js vjs-default-skin vjs-big-play-centered"
        ref={node => videoNode = node}
        preload="none"
        crossOrigin="anonymous"
        style={divStyle}
      />
    </div>
  )
}
