import Layout from "@components/layout/Layout/Layout"
import Explorer from "@partials/Explorer/Explorer"
import * as React from "react"

// markup
const ExplorerPage = () => (
  <Layout title="Masterbrews | Explore NFT Art">
    <Explorer />
  </Layout>
)

export default ExplorerPage
